// src/components/Equipment.js
import React from 'react';
import translations from '../../translations/Translations';
import equipmentImage from '../../images/equipment-image.jpg'; // Imaginile trebuie importate corespunzător
import './Equipment.css';
import AnimatedComponent from '../AnimatedComponent';
import { Truck } from 'react-feather'; // Importăm iconurile necesare
import FadeInUp from '../FadeInUp';
import StatsCard from '../StatsCard';

const Equipment = ({ language }) => {
  const availableTrucks = 25;

  return (
    <section id="equipment">
      <div className="equipment-content">
        <div className="equipment-text-container">
          <div className="equipment-text">
            <AnimatedComponent>
              <FadeInUp>
                <h2>{translations[language].equipment.title}</h2>
                <p>{translations[language].equipment.description}</p>
              </FadeInUp>
              <FadeInUp>
                <div className="equipment-cards-container">
                  <StatsCard icon={Truck} number={availableTrucks} label={translations[language].equipment.availableTrucks} />
                </div>
              </FadeInUp>
            </AnimatedComponent>
          </div>
        </div>
        <div className="equipment-image">
          <img src={equipmentImage} alt="Equipment" />
        </div>
      </div>
    </section>
  );
};

export default Equipment;

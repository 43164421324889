import React, { useState } from 'react';
import translations from '../../translations/Translations';
import videoBackground from '../../videos/home-background.mp4'; // Asigură-te că videoclipul este importat corect
import './Home.css';
import FadeInUp from '../FadeInUp';
import AnimatedComponent from '../AnimatedComponent';
import CustomButton from '../CustomButton';
import logo from '../../assets/logo.png';

const Home = ({ language }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const subject = `Mesaj de la ${name}`;
    const body = `Companie: ${name}\nEmail: ${email}\n\nMesaj:\n${message}`;

    const mailtoLink = `mailto:office@gabrieldeliverylogistique.ro?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  return (
    <section id="home">
      <div className="home-content">
        <div className="home-text-container">
          <div className="home-text">
            <FadeInUp>
              <img src={logo} alt="Logo" className="home-logo" />
              <p>{translations[language].home.description}</p>
              <CustomButton
                text={translations[language].home.seeMore}
                scrollTo="services"
              />
            </FadeInUp>
          </div>
        </div>
        <div className="home-video">
          <video playsInline autoPlay loop muted className="video-background">
            <source src={videoBackground} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="contact-form">
          <FadeInUp>
            <h2>{translations[language].home.formTitle}</h2>
            <p>{translations[language].home.formDescription}</p>
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">{translations[language].home.formName}</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />

              <label htmlFor="email">{translations[language].home.formEmail}</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <label htmlFor="message">{translations[language].home.formMessage}</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />

              <button type="submit">{translations[language].home.formSend}</button>
            </form>
          </FadeInUp>
        </div>
      </div>
    </section >
  );
};

export default Home;

import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ReactCountryFlag from "react-country-flag";
import { FaFacebook, FaBars, FaTimes } from 'react-icons/fa';
import logo from '../assets/logo.png';
import translations from '../translations/Translations';
import './Navbar.css';

const Navbar = ({ language, onLanguageChange }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const scrollOptions = {
    duration: 500,
    smooth: true,
    offset: -window.innerHeight / 2 + 350,
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    let lastScrollTop = 0;
    
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        setHidden(true);  // Ascunde navbar-ul
      } else {
        setHidden(false); // Afișează navbar-ul
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Previne erori
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={hidden ? 'hidden' : ''}>
      <div className="logo">
        <RouterLink to="/">
          <img src={logo} alt="Company Logo" />
        </RouterLink>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
      <ul className={isMenuOpen ? 'nav-menu active' : 'nav-menu'}>
        {isHomePage ? (
          <>
            <li>
              <ScrollLink to="home" {...scrollOptions} onClick={toggleMenu}>{translations[language].navbar.home}</ScrollLink>
            </li>
            <li>
              <ScrollLink to="services" {...scrollOptions} onClick={toggleMenu}>{translations[language].navbar.services}</ScrollLink>
            </li>
            <li>
              <ScrollLink to="qualities" {...scrollOptions} onClick={toggleMenu}>{translations[language].navbar.qualities}</ScrollLink>
            </li>
            <li>
              <ScrollLink to="equipment" {...scrollOptions} onClick={toggleMenu}>{translations[language].navbar.equipment}</ScrollLink>
            </li>
            <li>
              <ScrollLink to="about-us" {...scrollOptions} onClick={toggleMenu}>{translations[language].navbar.about}</ScrollLink>
            </li>
            <li>
              <ScrollLink to="contact-us" {...scrollOptions} onClick={toggleMenu}>{translations[language].navbar.contact}</ScrollLink>
            </li>
          </>
        ) : (
          <>
            <li>
              <RouterLink to="/" onClick={toggleMenu}>{translations[language].navbar.home}</RouterLink>
            </li>
            <li>
              <RouterLink to="/" onClick={toggleMenu}>{translations[language].navbar.services}</RouterLink>
            </li>
            <li>
              <RouterLink to="/" onClick={toggleMenu}>{translations[language].navbar.qualities}</RouterLink>
            </li>
            <li>
              <RouterLink to="/" onClick={toggleMenu}>{translations[language].navbar.equipment}</RouterLink>
            </li>
            <li>
              <RouterLink to="/" onClick={toggleMenu}>{translations[language].navbar.about}</RouterLink>
            </li>
            <li>
              <RouterLink to="/" onClick={toggleMenu}>{translations[language].navbar.contact}</RouterLink>
            </li>
          </>
        )}
        <li className="language-selector">
          <button onClick={() => onLanguageChange('ro')}>
            <ReactCountryFlag countryCode="RO" svg />
          </button>
          <button onClick={() => onLanguageChange('en')}>
            <ReactCountryFlag countryCode="GB" svg />
          </button>
        </li>
        <li className="facebook-icon">
          <RouterLink to="https://www.facebook.com/people/Sc-Gabriel-Delivery-Logistique-Srl-Transport-Trucking-Dispatch/100071279843396/" target="_blank">
            <FaFacebook />
          </RouterLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;

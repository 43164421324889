import React from 'react';
import { useSpring, animated } from 'react-spring';
import './Card.css';

const Card = ({ image, title, description, delay }) => {
  const animationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay,
  });

  return (
    <animated.div className="card" style={animationProps}>
      <img src={image} alt={title} className="card-image" />
      <div className="card-content">
        <h3 className="card-title">{title}</h3>
        <p className="card-description">{description}</p>
      </div>
    </animated.div>
  );
};

export default Card;

// src/components/AppRoutes.js
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Element } from 'react-scroll';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Qualities from './components/pages/Qualities';
import Equipment from './components/pages/Equipment';
import AboutUs from './components/pages/AboutUs';
import ContactUs from './components/pages/ContactUs';
import Gallery from './components/pages/Gallery';
import AnimatedComponent from './components/AnimatedComponent';
import Strengths from './components/pages/Strengths';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsAndConditions from './components/pages/TermsAndConditions';
import CookiesPolicy from './components/pages/CookiesPolicy';

const AppRoutes = ({ language }) => {
  return (
    <Routes>
      <Route path="/" element={
        <>
          <Element name="home">
            <Home language={language} />
            <AnimatedComponent>
              <Strengths language={language} />
            </AnimatedComponent>
          </Element>
          <Element name="services">
            <AnimatedComponent>
              <Services language={language} />
            </AnimatedComponent>
          </Element>
          <Element name="qualities">
            <AnimatedComponent>
              <Qualities language={language} />
            </AnimatedComponent>
          </Element>
          <Element name="equipment">
            <AnimatedComponent>
              <Equipment language={language} />
            </AnimatedComponent>
          </Element>
          <Element name="about-us">
            <AnimatedComponent>
              <AboutUs language={language} />
            </AnimatedComponent>
          </Element>
          <Element name="contact-us">
            <ContactUs language={language} />
          </Element>
        </>
      } />
      <Route path="/gallery" element={
        <>
          <Element name="gallery">
            <Gallery language={language} />
          </Element>
          <Element name="contact-us">
            <ContactUs language={language} />
          </Element>
        </>
      } />
      <Route path="/terms-and-conditions" element={
        <>
          <Element name="terms-and-conditions">
            <TermsAndConditions language={language} />
          </Element>
          <Element name="contact-us">
            <ContactUs language={language} />
          </Element>
        </>
      } />
      <Route path="/privacy-policy" element={
        <>
          <Element name="privacy-policy">
            <PrivacyPolicy language={language} />
          </Element>
          <Element name="contact-us">
            <ContactUs language={language} />
          </Element>
        </>
      } />
      <Route path="/cookies-policy" element={
        <>
          <Element name="cookies-policy">
            <CookiesPolicy language={language} />
          </Element>
          <Element name="contact-us">
            <ContactUs language={language} />
          </Element>
        </>
      } />
    </Routes>
  );
};

export default AppRoutes;

import React from 'react';
import translations from '../../translations/Translations';
import './ContactUs.css';
import { Mail, Phone, MapPin } from 'react-feather'; // Importăm iconițele necesare
import FadeInUp from '../FadeInUp';
import AnimatedComponent from '../AnimatedComponent';
import anpc from '../../images/anpc.png'
import anpc2 from '../../images/anpc2.png'
import { useNavigate } from 'react-router-dom';

const ContactUs = ({ language }) => {
  const navigate = useNavigate();

  return (
    <section id="contact-us">
      <FadeInUp>
        <h2>{translations[language].contactUs.title}</h2>
      </FadeInUp>
      <AnimatedComponent>
        <div className="contact-columns">
          <div className="contact-column">
            <FadeInUp>
              <h3><MapPin size={20} /> {translations[language].contactUs.address}</h3>
              <p>{translations[language].contactUs.addressDetails}</p>
              <iframe
                title="company-location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1899.8756744769962!2d26.289505166516996!3d47.66829988191464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4734fc0a8b8de3a5%3A0xdc25ffa61143afa7!2sStrada%20Cuza%20Vod%C4%83%2061%2C%20Suceava!5e0!3m2!1sro!2sro!4v1726920500752!5m2!1sro!2sro"
                width="300"
                height="200"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </FadeInUp>
          </div>
          <div className="contact-column">
            <FadeInUp>
              <h3><Mail size={20} /> {translations[language].contactUs.email}</h3>
              <p><a href="mailto:office@gabrieldeliverylogistique.com">office@gabrieldeliverylogistique.ro</a></p>
              <h3><Phone size={20} /> {translations[language].contactUs.phone}</h3>
              <p><a href="tel:+40750892122">+40 750 892 122</a></p>
              <h3>CIF: RO44015321</h3>
              <h3>Reg. com.: J33/639/2021</h3>
            </FadeInUp>
          </div>
          <div className="contact-column">
            <FadeInUp>
              <h3>{translations[language].contactUs.usefulLinks}</h3>
              <ul>

                <li onClick={() => navigate('/terms-and-conditions')}>
                  {translations[language].contactUs.terms}
                </li>
                <li onClick={() => navigate('/privacy-policy')}>
                  {translations[language].contactUs.privacy}
                </li>
                <li onClick={() => navigate('/cookies-policy')}>
                  {translations[language].contactUs.cookies}
                </li>
                <a href="https://anpc.ro/ce-este-sal/" target="_blank" rel="nofollow noopener" data-title="ANPC" title="ANPC">
                  <img width="130" height="27" src={anpc} alt="ANPC"></img>
                </a>
                <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="nofollow noopener" data-title="SOL" title="SOL">
                  <img width="130" height="27" src={anpc2} alt="ANPC"></img>
                </a>
                <hr width="100%" size="1" />
                <div className="mini-footer">
                  <p>Copyright ©2024 SC GABRIEL DELIVERY LOGISTIQUE SRL</p>
                  <p>
                    Made with <span role="img" aria-label="heart">❤️</span> by
                    <a href="https://www.linkedin.com/in/alexandru-ciornei-7962931b3/" target="_blank" rel="noopener noreferrer">Alex</a>
                  </p>
                </div>
              </ul>
            </FadeInUp>
          </div>
        </div>
      </AnimatedComponent>
    </section>
  );
};

export default ContactUs;

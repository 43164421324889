import React from 'react';
import translations from '../../translations/Translations';
import servicesImage from '../../images/services-image.jpg';
import './Services.css';
import AnimatedComponent from '../AnimatedComponent';
import StatsCard from '../StatsCard';
import { Users, Clipboard, PhoneCall } from 'react-feather'; 
import FadeInUp from '../FadeInUp';

const Services = ({ language }) => {
    const completedRides = 672;
    const satisfiedClients = 35;
    const dispeceri = 5;

    return (
        <section id="services">
            <div className="services-content">
                <div className="services-text-container">
                    <div className="services-text">
                        <AnimatedComponent>
                            <FadeInUp>
                                <h2>{translations[language].services.title}</h2>
                                <p>{translations[language].services.description}</p>
                            </FadeInUp>
                            <FadeInUp>
                                <div className="stats-cards-container">
                                    <StatsCard icon={Clipboard} number={completedRides} label={translations[language].services.completedRides} />
                                    <StatsCard icon={Users} number={satisfiedClients} label={translations[language].services.satisfiedClients} />
                                    <StatsCard icon={PhoneCall} number={dispeceri} label={translations[language].services.dispatch} />
                                </div>
                            </FadeInUp>
                        </AnimatedComponent>
                    </div>
                </div>
                <div className="services-image">
                    <img src={servicesImage} alt="Services" />
                </div>
            </div>
        </section>
    );
};

export default Services;

import React, { useState } from 'react';
import Slider from 'react-slick';
import translations from '../../translations/Translations';
import TeamCard from '../TeamCard';
import image1 from '../../assets/placeholder.jpg';
import { Mail, PhoneCall, MessageCircle, MapPin } from 'react-feather';
import './Gallery.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function importAll(r) {
    return r.keys().map(r);
}

const Gallery = ({ language }) => {
    const images = importAll(require.context('../../gallery', false, /\.(png|jpe?g|svg)$/));
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        message: ''
    });

    const teamMembers = [
        {
            image: image1,
            name: 'Gavriliuc Ionuț Gabriel',
            position: 'CEO',
            contact: 'gabriel@gabrieldeliverylogistique.ro',
        },
        {
            image: image1,
            name: 'Gavriliuc Andrei Valerică',
            position: 'Manager',
            contact: 'andrei@gabrieldeliverylogistique.ro',
        },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const mailtoLink = `mailto:office@gabrieldeliverylogistique.ro?subject=Informatii%20suplimentare&body=
            Nume: ${formData.name}%0D%0A
            Companie: ${formData.company}%0D%0A
            Email: ${formData.email}%0D%0A
            Telefon: ${formData.phone}%0D%0A
            Mesaj: ${formData.message}`;

        window.location.href = mailtoLink;
        setFormData({ name: '', company: '', email: '', phone: '', message: '' });
    };

    return (
        <section id="gallery" className="gallery-container">
            <div className="contact-form-wrapper">
                <div className="contact-info-section">
                    <h2>{translations[language].gallery.contactTitle}</h2>
                    <p><MapPin />{translations[language].contactUs.addressDetails}</p>
                    <p><Mail />{translations[language].contactUs.emailAddress}</p>
                    <p><PhoneCall />{translations[language].contactUs.phoneNumber}</p>
                </div>
                <div className="info-section">
                    <h2>{translations[language].gallery.title}</h2>
                    <p>{translations[language].gallery.content}</p>

                    <div className="team-section">
                        <div className="team-cards">
                            {teamMembers.map((member, index) => (
                                <TeamCard
                                    key={index}
                                    image={member.image}
                                    name={member.name}
                                    position={member.position}
                                    contact={member.contact}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="form-section">
                    <h2>{translations[language].gallery.contactUs}</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                type="text"
                                name="name"
                                placeholder={translations[language].gallery.name}
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            <input
                                type="text"
                                name="company"
                                placeholder="Companie"
                                value={formData.company}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                name="email"
                                placeholder={translations[language].gallery.email}
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <input
                                type="text"
                                name="phone"
                                placeholder={translations[language].gallery.phone}
                                value={formData.phone}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <textarea
                                name="message"
                                placeholder={translations[language].gallery.message}
                                value={formData.message}
                                onChange={handleChange}
                                required
                            ></textarea>
                        </div>
                        <button type="submit" className="submit-button">{translations[language].gallery.sendEmail}</button>
                    </form>
                </div>
            </div>
            <div className="gallery-carousel">
                    <Slider {...settings} className="gallery-carousel-slider">
                        {images.map((image, index) => (
                            <div key={index} className="gallery-image">
                                <img src={image} />
                            </div>
                        ))}
                    </Slider>
                </div>
        </section>
    );
};

export default Gallery;

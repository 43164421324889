import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import './StatsCard.css';
import { useInView } from 'react-intersection-observer';

const StatsCard = ({ icon: IconComponent, number, label }) => {
    const [counted, setCounted] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView) {
            setIsVisible(true);
        }
    }, [inView]);

    useEffect(() => {
        if (isVisible) {
            setCounted(number); 
        }
    }, [isVisible, number]);

    return (
        <div className="stats-card" ref={ref}>
            <div className="stats-icon">
                <IconComponent size={32} />
            </div>
            <div className="stats-info">
                <CountUp start={0} end={counted} duration={2.5} separator="," delay={0}>
                    {({ countUpRef }) => (
                        <div>
                            <span ref={countUpRef} className="stats-number" />
                            <span className="stats-label">{label}</span>
                        </div>
                    )}
                </CountUp>
            </div>
        </div>
    );
};

StatsCard.propTypes = {
    icon: PropTypes.elementType.isRequired,
    number: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
};

export default StatsCard;

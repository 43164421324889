// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/Navbar';
import AppRoutes from './AppRoutes';
import './App.css';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const [language, setLanguage] = useState('ro');

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  return (
    <Router>
      <div className="App">
        <div className="main-content">
          <ScrollToTop />
          <Navbar language={language} onLanguageChange={handleLanguageChange} />
          <AppRoutes language={language} />
        </div>
      </div>
    </Router>
  );
}

export default App;

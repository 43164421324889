// src/components/FadeInUp.js
import React, { Children } from 'react';
import { useTrail, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

const FadeInUp = ({ children, delay = 750, itemDelay = 500 }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.35,
  });

  const trail = useTrail(Children.count(children), {
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(20px)',
    from: { opacity: 0, transform: 'translateY(20px)' },
    config: { tension: 170, friction: 26 },
    delay: delay,
  });

  return (
    <div ref={ref}>
      {trail.map((style, index) => (
        <animated.div key={index} style={{ ...style, delay: itemDelay * index }}>
          {Children.toArray(children)[index]}
        </animated.div>
      ))}
    </div>
  );
};

FadeInUp.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  itemDelay: PropTypes.number,
};

export default FadeInUp;

const translations = {
  en: {
    navbar: {
      home: "Home",
      services: "Services",
      qualities: "Qualities",
      equipment: "Equipment",
      about: "About us",
      contact: "Contact us",
      gallery: "Gallery"
    },
    home: {
      welcome: "Gabriel Delivery Logistique",
      description: "We’re experts in ground freight transport and dispatch services, making sure your goods arrive safely and right on time. With a modern fleet, skilled drivers, and dispatch services to keep everything running smoothly, we tailor our services to meet your specific needs.",
      seeMore: "Find out more",
      formTitle: "Get your custom offer",
      formDescription: "Contact us today to discuss about your needs in terms of dispatch or logistics and to also find out on how we can help you achieve your milestones for your company.",
      formName: "Company",
      formEmail: "Email",
      formMessage: "Message",
      formSend: "Send"
    },
    strengths: {
      title: "What sets us apart",
      expertTeam: {
        title: 'Experienced team',
        description: 'Our team is made up of logistics professionals who know the ins and outs of the industry. We’re committed to delivering top-notch service and keeping things moving efficiently.',
      },
      innovativeSolutions: {
        title: 'Smart solutions',
        description: 'From optimizing routes to using the latest tech, we’re always looking for smarter ways to get your goods delivered quickly and safely.',
      },
      customerSatisfaction: {
        title: 'Customer focus',
        description: 'We don’t just transport goods—we build relationships. Your satisfaction is our priority, and we’re here to ensure each shipment meets your expectations.',
      },
    },
    services: {
      title: "Our services",
      description: "We offer a complete range of freight services: full truckload (FTL), less-than-truckload (LTL), express shipping, and specialized transport for oversized or fragile cargo. Plus, our dedicated truck dispatch service ensures your transport is always in good hands.",
      completedRides: "Successful deliveries",
      satisfiedClients: "Happy customers",
      dispatch: "Dispatchers available"
    },
    qualities: {
      title: "Why choose us",
      description: "We take pride in our reliability, timeliness, and the personal touch we bring to every delivery. Your cargo is important to us, and we make sure it’s handled with care from start to finish."
    },
    equipment: {
      title: "Our fleet & tools",
      description: "Our fleet includes modern, state-of-the-art trucks equipped with GPS tracking and temperature-controlled trailers, ensuring your goods are transported safely and efficiently.",
      availableTrucks: "Available trucks"
    },
    aboutUs: {
      title: "About us",
      description: "Gabriel Delivery Logistique is more than just a transport company—we’re a team committed to excellence in freight logistics. With years of experience and a focus on reliability, we aim to be your go-to partner for all your transportation needs."
    },
    contactUs: {
      title: 'Get in touch',
      address: 'Address',
      addressDetails: '61A Cuza Vodă Street, Suceava, Romania',
      emailAddress: 'office@gabrieldeliverylogistique.ro',
      email: 'Email',
      phone: 'Phone',
      phoneNumber: '+40 750 892 122',
      usefulLinks: 'Useful Links',
      terms: 'Terms & Conditions',
      privacy: 'Privacy Policy',
      cookies: 'Cookies Policy'
    },
    gallery: {
      title: 'Our work in action',
      content: 'Take a look at our gallery to see our fleet, the cargo we transport, and the high standards we maintain in every job we do. From start to finish, we’re committed to safety and quality.',
      contactUs: 'Fill the form. It\'s\ easy.',
      contactTitle: 'Get in touch with our expert team',
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      message: 'Message',
      sendEmail: 'Send'
    },
  },
  ro: {
    navbar: {
      home: "Acasă",
      services: "Servicii",
      qualities: "Calități",
      equipment: "Echipament",
      about: "Despre noi",
      contact: "Contactează-ne",
      gallery: "Galerie"
    },
    home: {
      welcome: "Gabriel Delivery Logistique",
      description: "Suntem specializați în transporturi rutiere de marfă și servicii de dispecerat, asigurând livrarea rapidă și sigură a mărfurilor tale. Cu o flotă modernă și servicii de dispecerat pentru camioane, îți oferim soluții personalizate care răspund exact nevoilor tale.",
      seeMore: "Află mai multe",
      formTitle: "Solicită oferta noastră",
      formDescription: "Contactați-ne astăzi pentru a discuta despre nevoile dumneavoastră de transport și pentru a afla cum vă putem ajuta să îndepliniți cu succes obiectivele afacerii dumneavoastră.",
      formName: "Companie",
      formEmail: "Email",
      formMessage: "Mesaj",
      formSend: "Trimite"
    },
    strengths: {
      title: "De ce să ne alegi",
      expertTeam: {
        title: 'Echipă cu experiență',
        description: 'Echipa noastră este formată din profesioniști care cunosc bine domeniul logisticii. Suntem dedicați să oferim servicii de încredere și să gestionăm eficient fiecare transport.',
      },
      innovativeSolutions: {
        title: 'Soluții inteligente',
        description: 'Optimizăm rutele și folosim tehnologia de ultimă generație pentru a asigura livrări rapide și sigure.',
      },
      customerSatisfaction: {
        title: 'Clientul pe primul loc',
        description: 'Nu transportăm doar marfă, ci construim relații. Satisfacția ta este prioritatea noastră, și ne asigurăm că fiecare transport îți îndeplinește așteptările.',
      },
    },
    services: {
      title: "Serviciile noastre",
      description: "Oferim o gamă completă de servicii de transport: transport complet (FTL), transport parțial (LTL), expediere rapidă și transport specializat pentru mărfuri supradimensionate sau sensibile. De asemenea, serviciul nostru de dispecerat camioane garantează că transportul tău este gestionat în siguranță și cu atenție.",
      completedRides: "Transporturi realizate",
      satisfiedClients: "Clienți mulțumiți",
      dispatch: "Dispeceri disponibili"
    },
    qualities: {
      title: "Calitățile noastre",
      description: "Punctualitatea, fiabilitatea și atenția la detalii sunt doar câteva dintre motivele pentru care clienții ne aleg. Ne asigurăm că fiecare transport este realizat cu grijă și profesionalism."
    },
    equipment: {
      title: "Echipamentul nostru",
      description: "Flota noastră include camioane moderne, echipate cu sisteme GPS și remorci controlate termic, pentru a asigura transportul sigur și eficient al mărfurilor tale.",
      availableTrucks: "Camioane disponibile"
    },
    aboutUs: {
      title: "Despre noi",
      description: "Gabriel Delivery Logistique nu este doar o companie de transport – suntem o echipă dedicată excelenței în logistica transportului de marfă. Cu ani de experiență și un angajament pentru servicii de încredere, suntem partenerul tău de încredere în transporturi."
    },
    contactUs: {
      title: 'Contactează-ne',
      address: 'Adresă',
      addressDetails: 'Strada Cuza Vodă 61A, Suceava, România',
      emailAddress: 'office@gabrieldeliverylogistique.ro',
      email: 'Email',
      phone: 'Telefon',
      phoneNumber: '+40 750 892 122',
      usefulLinks: 'Linkuri Utile',
      terms: 'Termeni și Condiții',
      privacy: 'Politica de Confidențialitate',
      cookies: 'Politica de Cookies'
    },
    gallery: {
      title: 'Haide să vorbim!',
      content: 'Explorează galeria noastră pentru a vedea flota, mărfurile pe care le transportăm și standardele înalte pe care le respectăm. Suntem dedicați calității și siguranței în fiecare etapă a transportului.',
      contactUs: 'Completează formularul. Este simplu.',
      contactTitle: 'Contactează echipa noastră de experți',
      name: 'Nume',
      email: 'Email',
      phone: 'Telefon',
      message: 'Mesaj',
      sendEmail: 'Trimite'
    },
  },
};

export default translations;

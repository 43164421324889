// src/components/AboutUs.js
import React from 'react';
import translations from '../../translations/Translations';
import aboutUsImage from '../../images/about-us-image.jpg'; // Imaginea trebuie să fie importată corespunzător
import './AboutUs.css';
import AnimatedComponent from '../AnimatedComponent';
import CustomButton from '../CustomButton';
import FadeInUp from '../FadeInUp';

const AboutUs = ({ language }) => {
  return (
    <section id="about-us">
      <div className="about-us-content">
        <div className="about-us-text">
          <AnimatedComponent>
            <FadeInUp>
              <h2>{translations[language].aboutUs.title}</h2>
              <p>{translations[language].aboutUs.description}</p>
              <CustomButton text={translations[language].navbar.gallery} to='/gallery' />
            </FadeInUp>
          </AnimatedComponent>
        </div>
        <div className="about-us-image">
          <img src={aboutUsImage}></img>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;

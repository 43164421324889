// src/components/CustomButton.js
import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import './CustomButton.css';

const CustomButton = ({ text, to, href, scrollTo, className = '', ...props }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    if (href) {
      // Redirect to external link
      window.location.href = href;
    } else if (to) {
      // Navigate to internal route
      navigate(to);
    } else if (props.onClick) {
      // Handle custom onClick
      props.onClick(e);
    }
  };

  if (scrollTo) {
    return (
      <ScrollLink
        to={scrollTo}
        smooth={true}
        duration={500}
        offset={-window.innerHeight / 2 + 350}
        className={`custom-button ${className}`}
      >
        {text}
      </ScrollLink>
    );
  }

  return (
    <button className={`custom-button ${className}`} onClick={handleClick} {...props}>
      {text}
    </button>
  );
};

export default CustomButton;

import React, { useEffect, useRef } from 'react';
import './AnimatedComponent.css';

const AnimatedComponent = ({ children }) => {
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        } else {
          //entry.target.classList.remove('animate');
        }
      });
    }, {
      threshold: 0.5,
      root: document.body,
      rootMargin: "0px"
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref} className="animated-component">
      {children}
    </div>
  );
};

export default AnimatedComponent;

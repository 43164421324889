import React from 'react';
import Card from '../Card';
import './Strengths.css';
import translations from '../../translations/Translations';
import image1 from '../../images/expert-team.jpg';
import image2 from '../../images/innovative-solutions.jpg';
import image3 from '../../images/customer-satisfaction.jpg';
import { useTrail, animated } from 'react-spring';
import FadeInUp from '../FadeInUp';
import AnimatedComponent from '../AnimatedComponent';

const Strengths = ({ language }) => {
  const strengthsData = [
    {
      image: image1,
      title: translations[language].strengths.expertTeam.title,
      description: translations[language].strengths.expertTeam.description,
    },
    {
      image: image2,
      title: translations[language].strengths.innovativeSolutions.title,
      description: translations[language].strengths.innovativeSolutions.description,
    },
    {
      image: image3,
      title: translations[language].strengths.customerSatisfaction.title,
      description: translations[language].strengths.customerSatisfaction.description,
    },
  ];

  const trail = useTrail(strengthsData.length, {
    opacity: 1,
    from: { opacity: 0 },
    delay: 1000,
  });

  return (
    <section id="strengths">
      <FadeInUp>
        <h2 className="strengths-title">{translations[language].strengths.title}</h2>
      </FadeInUp>
      <AnimatedComponent>
          <div className="strengths-container">
            {trail.map((animationProps, index) => (
              <animated.div key={index} style={animationProps}>
                <Card
                  image={strengthsData[index].image}
                  title={strengthsData[index].title}
                  description={strengthsData[index].description}
                  delay={index * 500}
                />
              </animated.div>
            ))}
          </div>
      </AnimatedComponent>
    </section>
  );
};

export default Strengths;

import React from 'react';
import PropTypes from 'prop-types';
import './TeamCard.css';

const TeamCard = ({ image, name, position, contact }) => {
    return (
        <div className="team-card">
            <div className="team-card-image">
                <img src={image} alt={name} />
            </div>
            <div className="team-card-info">
                <h3>{name}</h3>
                <p>{position}</p>
                <p>{contact}</p>
            </div>
        </div>
    );
};

TeamCard.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
};

export default TeamCard;

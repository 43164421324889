// src/components/Qualities.js
import React from 'react';
import translations from '../../translations/Translations';
import qualitiesImage from '../../images/qualities-image.jpg';
import './Qualities.css';
import FadeInUp from '../FadeInUp';
import CustomButton from '../CustomButton';

const Qualities = ({ language }) => {
  return (
    <section id="qualities">
      <div className="qualities-content">
        <div className="qualities-text-container">
          <div className="qualities-text">
            <FadeInUp>
              <h2>{translations[language].qualities.title}</h2>
              <p>{translations[language].qualities.description}</p>

              <CustomButton
                text={translations[language].home.seeMore}
                scrollTo="about-us"
              />
            </FadeInUp>
          </div>
        </div>
        <div className="qualities-image">
          <img src={qualitiesImage} alt="Qualities" />
        </div>
      </div>
    </section>
  );
};

export default Qualities;
